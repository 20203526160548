window.hw = window.hw || {};

import { trackEvent } from '../js/plugins/UrchinPlugin';

hw.cookieGate = (function () {
  var containerID = 'hw-cookie-alert',
    titleID = 'cookie-notification-title',
    viewedCookie = getCookie('viewedCookieDisclaimer');

  function getCookie(cookieName) {
    var name = cookieName + '=',
      decodedCookie = decodeURIComponent(document.cookie),
      availableCookies = decodedCookie.split(';'),
      availableCookiesLength = availableCookies.length;

    for (var i = 0; i < availableCookiesLength; i++) {
      var cookie = availableCookies[i];
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) == 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  }

  // http://youmightnotneedjquery.com/#ready
  function onDomReady(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
  }

  function init(config) {
    if (viewedCookie === 'true') {
      return;
    }

    config = config || {};

    onDomReady(function () {
      var defaults = setDefaults(config);
      buildDOM(defaults);
      buildStyles(defaults);
      trackCookieGateEvent('Display');
    });
  }

  function close() {
    var disclaimerContainer = document.getElementById(containerID),
      modalBackground = document.getElementsByClassName('modal-background')[0];

    if (!disclaimerContainer.classList.contains('close')) {
      disclaimerContainer.classList.add('close');

      if (modalBackground) {
        modalBackground.classList.add('close');
      }

      setTimeout(function () {
        document.body.removeChild(disclaimerContainer);
        if (modalBackground) {
          document.body.removeChild(modalBackground);
        }
      }, 1000);

      document.cookie = 'viewedCookieDisclaimer=true';
      trackCookieGateEvent('Accept');
    }
  }

  function buildDOM(config) {
    var container = document.createElement('div'),
      content_container = document.createElement('div'),
      title = document.createElement('h1'),
      body_copy = document.createElement('p'),
      button;

    title.id = titleID;
    title.innerHTML = config.title;
    title.classList.add('visually-hidden');

    body_copy.innerHTML = config.body_copy;

    if (config.button_type !== 'button') {
      var button = document.createElement('div');
      var icon = document.createElement('i');
      icon.classList.add('fas');
      icon.classList.add('fa-times');
      button.appendChild(icon);
    } else {
      button = document.createElement('button')
      button.classList.add('btn');
      button.innerHTML = config.button_copy;
    }

    button.setAttribute('data-e2e', 'cookie-gate-close');

    button.addEventListener('click', close);

    container.id = containerID;
    container.classList.add(config.style);
    container.setAttribute('role', 'complementary');
    content_container.classList.add('cg-content-container');
    content_container.setAttribute('role', 'dialog');
    content_container.setAttribute('aria-labelledby', titleID);
    content_container.appendChild(title);
    content_container.appendChild(body_copy);
    content_container.appendChild(button);
    container.appendChild(content_container);
    document.body.appendChild(container);

    if (config.style === 'popup') {
      var modalBackground = document.createElement('div');
      modalBackground.classList.add('modal-background');
      document.body.appendChild(modalBackground);
    }
  }

  function setDefaults(config) {
    var defaultCopy = 'To provide you with the best viewing experience, our site uses cookies and similar technologies. By continuing, you are giving permission to store information on your computer or device. To learn more, please visit the <a href="https://helloworld.com/privacy-policy.html" title="HelloWorld Privacy Policy (opens in a new window)" target="_blank">HelloWorld Privacy Policy</a>.',
      defaultTitle = 'Cookie Usage Notification',
      defaultButton = 'Continue',
      backgroundColor = 'white',
      fontColor = 'black',
      buttonColor = fontColor,
      buttonTextColor = backgroundColor,
      buttonType = 'button';

    return {
      style: config['style'] || 'toast',
      title: config['title'] || defaultTitle,
      body_copy: config['body_copy'] || defaultCopy,
      button_copy: config['button_copy'] || defaultButton,
      background_color: config['background_color'] || backgroundColor,
      font_color: config['font_color'] || fontColor,
      button_color: config['button_color'] || buttonColor,
      button_text: config['button_text'] || buttonTextColor,
      button_type: config['button_type'] || buttonType
    };
  }

  function buildStyles(config) {
    var css = document.createElement('style'),
      styleString = '';

    styleString += '#' + containerID + ' {box-sizing: border-box; transition: all .5s; padding: 20px; z-index:100; color: ' + config.font_color + ' }';
    styleString += '#' + containerID + ' button { flex-basis: 30%; margin: 0; padding: 10px 15px; background: ' + config.button_color + '; color: ' + config.button_text + ';}';
    styleString += '#' + containerID + ' svg { color: ' + config.button_color +'; display: block; cursor:pointer;}';
    styleString += '#' + containerID + ' .cg-content-container {margin: 0 auto; max-width: 960px; width: 100%; position:relative; display: flex; align-items: center;}'

    switch (config.style) {
      case 'popup':
        styleString += '#' + containerID + '.popup {position: absolute; width: 45%; left: 50%; top: 50%; transform: translate(-50%, -50%); box-shadow: 2px 2px 10px #333; opacity:1; background: ' + config.background_color + '; }';
        styleString += '#' + containerID + '.close {opacity: 0;}';
        styleString += '.modal-background {position: fixed; top: 0; left:0; right:0; bottom:0; background:black; opacity: .7; transition: all .5s; }';
        styleString += '.modal-background.close { opacity: 0 }';
        break;
      case 'toast':
        styleString += '#' + containerID + '.toast  { position: fixed; bottom: 0; left: 0; right: 0; padding-bottom: 50px; opacity: 1; border-top: 2px solid #333; background: ' + config.background_color + '; display:flex; align-items: center; font-size: 1.3em; }';

        styleString += '@media screen and (max-width: 768px) { #' + containerID + '.toast .cg-content-container {flex-flow: column; font-size: 1.8em; } #' + containerID + '.toast p { padding: 0 0 1rem !important; }}';
        styleString += '#' + containerID + '.toast p { font-size: 16px; flex-basis: 70%; padding: 0 20px 0 0;}';
        styleString += '#' + containerID + '.close {bottom: -200px; opacity: 0;}';
        break;
    }

    if (css.styleSheet) {
      css.styleSheet.cssText = styleString;
    } else {
      css.appendChild(document.createTextNode(styleString));
    }

    document.getElementsByTagName('head')[0].appendChild(css);
  }

  function trackCookieGateEvent(action) {
    trackEvent('Privacy', action, 'Cookie Notice', 1);
  }

  return { init: init };
})();
